import { useEffect, useState } from "react";
import {
  MONKEY_STATUS,
  MONKEY_TYPE,
  MONKEY_TYPE_W_LEVEL,
  useAppState,
} from "../state";
import {
  CONTRACT_ADDRESS,
  CONTRACT_NAME,
  GET_MUTANT_LEVEL_FUNC_NAME,
  transactionsAPI,
} from "../utils/auth";
import slime from "../assets/SLIME.png";

import {
  standardPrincipalCV,
  callReadOnlyFunction,
  cvToJSON,
  uintCV,
} from "@stacks/transactions";

type MonkeyTileProps = {
  monkey: MONKEY_TYPE;
  selectedMonkey: MONKEY_TYPE_W_LEVEL | null;
  handleSelectMonkey: (monkey: MONKEY_TYPE, level: number) => void;
};
const MonkeyTile = ({
  monkey,
  selectedMonkey,
  handleSelectMonkey,
}: MonkeyTileProps) => {
  const { senderAddress } = useAppState();
  const { monkeys, updateMonkey } = useAppState();
  const [loading, _loading] = useState(false);

  const [level, _level] = useState(0);

  useEffect(() => {
    if (monkey.status === MONKEY_STATUS.PROCESSING) {
      fetchTx(monkey.txId || "");
    }
    getCurrentLevel();
  }, []);

  const handleLinkTx = () => {
    window.open(`https://explorer.stacks.co/txid/${monkey.txId}?chain=mainnet`);
  };

  const handleClick = () => {
    if (
      monkey.status === MONKEY_STATUS.NOTHING ||
      monkey.status === MONKEY_STATUS.FAILED
    ) {
      handleSelectMonkey(monkey, level);
    } else {
      handleLinkTx();
    }
  };

  const getCurrentLevel = async () => {
    try {
      _loading(true);
      const otherThing = await callReadOnlyFunction({
        contractName: CONTRACT_NAME,
        contractAddress: CONTRACT_ADDRESS,
        functionName: GET_MUTANT_LEVEL_FUNC_NAME,
        functionArgs: [uintCV(monkey.id)],
        senderAddress: senderAddress || "",
      });

      const result = cvToJSON(otherThing);

      if (result) {
        _level(+result.value);
      } else {
        console.log("fetch monkey level did not reutrn");
      }
      _loading(false);
    } catch (err) {
      console.log("", err);
    }
  };

  const fetchTx = async (txId: string) => {
    try {
      const txStatus = (await transactionsAPI.getTransactionById({
        txId: txId,
      })) as any;

      if (txStatus && txStatus.tx_status && txStatus.tx_status === "success") {
        const updateMonkey = monkeys.find((d, i) => {
          if (d.id === monkey.id) {
            return {
              ...d,
              status: MONKEY_STATUS.UPDATED,
            };
          } else {
            return d;
          }
        });
      } else if (txStatus && txStatus.tx_status) {
        const error_code = "";
        let failedReason = "";
        if (txStatus.tx_status === "pending") {
          //show pending status
          //failedReason = "Pending";
          console.log("pending");
        } else {
          switch (txStatus.tx_result) {
            case "(err u0)":
              failedReason = "Refund Not Yet Active";
              break;
            case "(err u1)":
              failedReason = "Your Artie is not eligible ";
              break;
            case "(err u2)":
              failedReason = "Your wallet is not eligible ";
              break;
            case "(err u3)":
              failedReason = "Not original owner of this Artie";
              break;
            case "(err u4)":
              failedReason = "Something went wrong transfering STX";
              break;
            case "(err u5)":
              failedReason = "Not Authenticated to transfer this asset";
              break;
            default:
              failedReason = "Something went wrong";
          }
        }
      }
    } catch (err) {
      console.log("something went wrong wrong ", err);
    }
  };

  const selected = selectedMonkey !== null && selectedMonkey.id === monkey.id;

  return (
    <div
      onClick={() => handleClick()}
      className="flex flex-col items-center relative"
    >
      <img src={monkey.image} className="w-40 h-40" alt={"" + monkey.id} />
      <p className="text-sm text-gray mt-2">{`Mutant Monkey ${monkey.id}`}</p>
      <p className="text-sm text-gray mt-2">{`Level ${
        level !== 0 ? level : ""
      }`}</p>
      {selected && (
        <div className="absolute m-auto left-0 right-0 bottom-0 top-0">
          <img className=" w-10 h-10" src={slime} alt="" />
        </div>
      )}
    </div>
  );
};

export default MonkeyTile;
