import { useEffect, useState } from "react";
import {
  MONKEY_STATUS,
  MONKEY_TYPE,
  MONKEY_TYPE_W_LEVEL,
  useAppState,
} from "../state";

import slime from "../assets/SLIME.png";
import { openContractCall } from "@stacks/connect";
import {
  CONTRACT_NAME,
  UPGRADE_MUTANT_LEVEL_FUNC_NAME,
  CONTRACT_ADDRESS,
} from "../utils/auth";

import {
  standardPrincipalCV,
  callReadOnlyFunction,
  cvToJSON,
  AnchorMode,
  uintCV,
  makeStandardFungiblePostCondition,
  FungibleConditionCode,
  createAssetInfo,
} from "@stacks/transactions";
import { NETWORK } from "../const";
import { toast } from "react-toastify";
import BN from "bn.js";

type MonkeyControlProps = {
  selctedMonkey: MONKEY_TYPE_W_LEVEL | null;
};

const LEVELS = [1, 2, 3, 4, 5];
const MonkeyControl = ({ selctedMonkey }: MonkeyControlProps) => {
  const {
    _authenticated,
    authenticated,
    _senderAddress,
    senderAddress,
    monkeys,
    _monkeys,
  } = useAppState();
  const [selectedLevel, _selectedLevel] = useState(1);

  useEffect(() => {
    if (selctedMonkey) {
      _selectedLevel(selctedMonkey.level);
    }
  }, [selctedMonkey]);

  const handleLogout = () => {
    _authenticated(false);
    _senderAddress(undefined);
    _monkeys([]);
    localStorage.removeItem("walletID");
  };
  const handleClickLevel = (level: number) => {
    if (selctedMonkey) {
      if (level <= selctedMonkey.level) {
        //should not be able to cheange level
      } else {
        _selectedLevel(level);
      }
    } else {
      _selectedLevel(level);
    }
  };

  const handleValidTrans = (txId: string) => {
    toast.success(`Successful Slime`);
    const updateMonkeys = monkeys.map((d, i) => {
      if (selctedMonkey) {
        if (d.id === selctedMonkey.id) {
          return {
            ...d,
            txId: txId,
          };
        } else {
          return d;
        }
      } else {
        return d;
      }
    });

    _monkeys(updateMonkeys);
  };
  const hanldeLevelUp = async () => {
    if (
      selctedMonkey &&
      selctedMonkey.level < selectedLevel &&
      selectedLevel > 1
    ) {
      const fungibleAssetInfo = createAssetInfo(
        "SP125J1ADVYWGWB9NQRCVGKYAG73R17ZNMV17XEJ7",
        "slime-token",
        "SLIME"
      );

      const price = getPrice();
      const cleanPrice = new BN(price * 1000000);

      const pc = [
        makeStandardFungiblePostCondition(
          senderAddress || "",
          FungibleConditionCode.Equal,
          price * 1000000,
          fungibleAssetInfo
        ),
      ];

      try {
        const txOptions = {
          contractAddress: CONTRACT_ADDRESS,
          contractName: CONTRACT_NAME,
          functionName: UPGRADE_MUTANT_LEVEL_FUNC_NAME,
          functionArgs: [uintCV(selctedMonkey.id), uintCV(selectedLevel)],
          senderKey: senderAddress,
          validateWithAbi: true,
          network: NETWORK,
          postConditions: pc,
          anchorMode: AnchorMode.Any,
          onFinish: (data: any) => {
            handleValidTrans(data.txId);
          },
          onCancel: () => {
            console.log("err");
          },
        };
        const transaction = await openContractCall(txOptions);
      } catch (err) {
        console.log("err", err);
      }
    } else {
      toast.error("Please Select Level Greater Than Current Level");
    }
  };

  const getPrice = () => {
    const currentLevel = selctedMonkey?.level || 1;

    if (currentLevel === 1) {
      switch (selectedLevel) {
        case 1:
          return 0;
        case 2:
          return 50;
        case 3:
          return 50 + 100;
        case 4:
          return 50 + 100 + 150;
        case 5:
          return 50 + 100 + 150 + 225;
      }
    } else if (currentLevel === 2) {
      switch (selectedLevel) {
        case 3:
          return 100;
        case 4:
          return 100 + 150;
        case 5:
          return 100 + 150 + 225;
      }
    } else if (currentLevel === 3) {
      switch (selectedLevel) {
        case 4:
          return 150;
        case 5:
          return 150 + 225;
      }
    } else if (currentLevel === 4) {
      switch (selectedLevel) {
        case 5:
          return 225;
      }
    }

    return 0;
  };

  return (
    <div className="flex flex-col items-center">
      <div
        style={{
          minHeight: "580px",
          minWidth: "360px",
        }}
        className="border-primary rounded-3xl border-solid border-4 flex flex-col items-center py-4"
      >
        <p className="text-base text-textGray">
          {selctedMonkey
            ? `Mutant Monkey #${selctedMonkey.id}`
            : "Mutant Monkey"}
        </p>
        <div className="h-48 w-48 my-4 border-solid border-2 border-primary ">
          {selctedMonkey && <img src={selctedMonkey.image} alt="Monkey" />}
          {selctedMonkey === null && (
            <div className="w-full h-full flex flex-col items-center justify-center">
              <img className=" w-32 h-32" src={slime} alt="" />
            </div>
          )}
        </div>
        {selctedMonkey && (
          <a
            href={`https://gamma.io/collections/mutant-monkeys/${selctedMonkey.id}`}
            target="_blank"
          >
            <p className="HeaderFont text-xs text-white pb-2 underline pointer">
              View on Gamma
            </p>
          </a>
        )}
        <p className="text-base text-textGray pb-8 TextFont">
          {selctedMonkey
            ? `Current Level ${selctedMonkey?.level}`
            : "Select A Monkey To Level Up"}
        </p>

        <p className="text-2xl  text-gray HeaderFont text-center">
          Select Level
        </p>
        <div className="flex flex-row items-center justify-center w-full py-4">
          {LEVELS.map((d, i) => {
            return (
              <div
                className="flex flex-col items-center mx-4 justify-center"
                key={d}
                onClick={() => handleClickLevel(d)}
                style={{
                  width: "40px",
                  height: "40px",
                  backgroundColor: selectedLevel >= d ? "#8ced00" : "#D9D9D9",
                }}
              >
                <p className="text-xl text-center m-auto text-black HeaderFont text-center">
                  {d}
                </p>
              </div>
            );
          })}
        </div>
        <div className="flex flex-row items-center">
          <p className="text-lg mr-2 text-gray HeaderFont text-center">Cost:</p>
          <p className="text-lg  text-primary HeaderFont text-center">{`${getPrice()} $Slime`}</p>
          <div>
            <img className="ml-3 w-10 h-10" src={slime} alt="" />
          </div>
        </div>
        <div
          onClick={() => hanldeLevelUp()}
          className={`${
            selctedMonkey && selctedMonkey.level < selectedLevel
              ? "bg-primary"
              : "bg-gray"
          } w-48 h-12 mt-4 flex flex-col justify-center items-center cursor-pointer`}
        >
          <p className="text-xl mr-2 text-black HeaderFont text-center">
            Level Up
          </p>
        </div>
      </div>
      {authenticated && (
        <div onClick={() => handleLogout()} className="m-auto">
          <p className="text-sm text-gray underline  cursor-pointer  mt-4">
            Logout
          </p>
        </div>
      )}
    </div>
  );
};

export default MonkeyControl;
