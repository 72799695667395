import { useEffect, useState } from "react";
import { MONKEY_TYPE, MONKEY_TYPE_W_LEVEL, useAppState } from "../state";

import LoaderPlaceHolder from "./Loader";

import MMLogo from "../assets/MM-LOGO.png";

import { HOME_FILE_TYPE } from "../types";
import { toast } from "react-toastify";
import { height } from "styled-system";
import MonkeyControl from "./MonkeyControl";
import Board from "./Board";

const Home = () => {
  const [selctedMonkey, _selectedMonkey] = useState<MONKEY_TYPE_W_LEVEL | null>(
    null
  );

  const handleUpdateSelectedMonkey = (monkey: MONKEY_TYPE, level: number) => {
    const selectedMOnkeyWLevel = {
      ...monkey,
      level,
    };
    _selectedMonkey(selectedMOnkeyWLevel);
  };

  return (
    <div
      style={{
        maxWidth: "1800px",
      }}
      className="min-h-screen pt-20 w-11/12  2xl:w-11/12 relative flex flex-col items-center"
    >
      <div className="w-60 h-24 xl:absolute mb-6 left-0 top-16  float-left text-left">
        <img src={MMLogo} alt="Bitcoin Monkeys" />
      </div>
      <div>
        <h1 className=" HeaderFont mt-3.5 text-primary text-4xl  md:mt-0  md:text-5xl">
          Slime Laboratory
        </h1>
      </div>
      <div className="w-full pt-16 flex flex-col md:items-start md:justify-between md:flex-row ">
        <Board
          selctedMonkey={selctedMonkey}
          _selctedMonkey={handleUpdateSelectedMonkey}
        />
        <div className="md:px-10  ">
          <MonkeyControl selctedMonkey={selctedMonkey} />
        </div>
      </div>
    </div>
  );
};

export default Home;
