import { useConnect } from "@stacks/connect-react";

import { MONKEY_TYPE, MONKEY_TYPE_W_LEVEL, useAppState } from "../state";
import MonkeyTile from "./MonkeyTile";
import slime from "../assets/SLIME.png";
import MMLogo from "../assets/MM-LOGO.png";
import { isMobile } from "../utils";
import { useEffect, useState } from "react";

import {
  CONTRACT_ADDRESS,
  CONTRACT_NAME,
  GET_TOTAL_MONKEY_AT_LEVEL,
  STACKS_USER_SESSION,
} from "../utils/auth";

import {
  standardPrincipalCV,
  callReadOnlyFunction,
  cvToJSON,
  uintCV,
} from "@stacks/transactions";

type BoardProps = {
  selctedMonkey: MONKEY_TYPE_W_LEVEL | null;
  _selctedMonkey: (monkey: MONKEY_TYPE, level: number) => void;
};
const  Board = (props: BoardProps) => {
  const { doOpenAuth } = useConnect();
  const [totalLevel4, _totalLevel4] = useState(0);

  const { authenticated, senderAddress, monkeys } = useAppState();

  useEffect(() => {
    if (authenticated) {
      fetchTotalLevel4();
    }
  }, [authenticated]);

  const handleAuth = () => {
    doOpenAuth();
  };

  const fetchTotalLevel4 = async () => {
    try {
      const level4Check = await callReadOnlyFunction({
        contractName: CONTRACT_NAME,
        contractAddress: CONTRACT_ADDRESS,
        functionName: GET_TOTAL_MONKEY_AT_LEVEL,
        functionArgs: [uintCV(4)],
        senderAddress: senderAddress || "",
      });

      const level5Check = await callReadOnlyFunction({
        contractName: CONTRACT_NAME,
        contractAddress: CONTRACT_ADDRESS,
        functionName: GET_TOTAL_MONKEY_AT_LEVEL,
        functionArgs: [uintCV(5)],
        senderAddress: senderAddress || "",
      });
      const result4 = cvToJSON(level4Check);
      const result5 = cvToJSON(level5Check);

      if (result4 && result5) {
        const total = +result4.value + +result5.value;

        _totalLevel4(total);
      } else {
        console.log("Could Not fetch level 4");
      }
    } catch (err) {
      console.log("getCurrentLevel", err);
    }
  };
  return (
    <div
      style={{
        minHeight: isMobile ? "200px" : "540px",
      }}
      className="  rounded-3xl border-solid border-2 min-h-full w/1/3 xl:w-2/3"
    >
      <div className="flex flex-col-reverse xl:flex-row pt-10 pb-4 items-start justify-between">
        <p className="font-header text-2xl text-white  HeaderFont">
          Select Mutant Monkey
        </p>
        {authenticated ? (
          <div>
            <p className="font-header pb-8 xl:py-0 text-xl text-primary  HeaderFont">
              {senderAddress
                ? `${senderAddress.substring(0, 4)}...${senderAddress.substring(
                    senderAddress.length - 4
                  )}`
                : ""}
            </p>
          </div>
        ) : (
          <div
            style={{
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
            onClick={() => handleAuth()}
            className="bg-primary  mb-8 xl:mb-0 flex flex-col items-center px-8 rounded-sm"
          >
            <p className="text-base text-black  HeaderFont">Connect </p>
            <p className="text-base text-black  HeaderFont"> Wallet</p>
          </div>
        )}
      </div>

      {monkeys.length > 0 && (
        <div className="w-full py-4 px-2 flex flex-row items-center justify-between">
          <div className="flex flex-row items-center">
            <a
              href="https://gamma.io/collections/mutant-monkeys"
              target="_blank"
            >
              <p className="text HeaderFont underline mx-4 font-light text-white">
                Buy on Gamma
              </p>
            </a>
            <img className=" w-10 h-10" src={slime} alt="" />
          </div>

          <p className=" text-sm font-header text-right pb-8 xl:py-0 text-xl text-primary  HeaderFont">
            {`Total Lvl 4: ${totalLevel4}`}
          </p>
        </div>
      )}

      {monkeys.length > 0 ? (
        <div className=" grid  grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-col-6 gap-12">
          {monkeys.map((d, i) => {
            return (
              <MonkeyTile
                selectedMonkey={props.selctedMonkey}
                monkey={d}
                handleSelectMonkey={props._selctedMonkey}
                key={d.id + i}
              />
            );
          })}
        </div>
      ) : (
        <div
          style={{
            minHeight: "400px",
          }}
          className=" flex flex-col items-center justify-center"
        >
          <p className="text HeaderFont mx-4 font-light text-white">
            No Mutant Monkeys?
          </p>

          <a
            className="pointers"
            href="https://gamma.io/collections/mutant-monkeys"
            target="_blank"
          >
            <p className="text text-center HeaderFont underline mx-4 font-light text-white ">
              Buy Mutant Monkeys on Gamma
            </p>
          </a>
        </div>
      )}
    </div>
  );
};

export default Board;
