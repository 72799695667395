import React, { createContext, useContext, useState } from "react";
import { ACTIVE_TRACKING } from "./types";

export enum MONKEY_STATUS {
  UPDATED,
  PROCESSING,
  NOTHING,
  FAILED,
}
export type MONKEY_TYPE = {
  id: number;
  image: string;
  status: MONKEY_STATUS;
  txId?: string;
  failedReason?: string;

  meta: any;
};

export type MONKEY_TYPE_W_LEVEL = {
  id: number;
  image: string;
  status: MONKEY_STATUS;
  txId?: string;
  failedReason?: string;
  level: number;
  meta: any;
};

const INIT_STATE = {
  senderAddress: undefined,
  authenticated: false,
  monkeys: [],
};

type INIT_STATE_TYPE = {
  senderAddress?: string;
  authenticated: boolean;
  monkeys: MONKEY_TYPE[];

  _senderAddress: (address: string | undefined) => void;
  _authenticated: (authenticated: boolean) => void;

  _monkeys: (arties: MONKEY_TYPE[]) => void;
  updateMonkey: (monkey: MONKEY_TYPE) => void;
};

export const StateContext = createContext<INIT_STATE_TYPE>(null!);

const StateLogic = (props: React.PropsWithChildren<{}>) => {
  const [senderAddress, _senderAddress] = useState<string | undefined>(
    INIT_STATE.senderAddress
  );
  const [authenticated, _authenticated] = useState<boolean>(false);

  const [monkeys, _monkeys] = useState<MONKEY_TYPE[]>([]);

  const updateMonkey = (monkey: MONKEY_TYPE) => {
    const _fack = [...monkeys];

    const updatedMonkeys = _fack.map((monk, i) => {
      if (monk.id === monkey.id) {
        return monkey;
      } else {
        return monk;
      }
    });

    _monkeys(updatedMonkeys);
  };

  let contextValue = {
    senderAddress,
    _senderAddress,
    monkeys,
    _monkeys,
    authenticated,
    _authenticated,
    updateMonkey,
  };
  return (
    <StateContext.Provider value={{ ...contextValue }}>
      {props.children}
    </StateContext.Provider>
  );
};

export default StateLogic;

export const useAppState = () => {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
};
