import {
  AccountsApi,
  AddressNftListResponse,
  BlocksApi,
  Configuration,
  Middleware,
  SmartContractsApi,
  TransactionsApi,
} from "@stacks/blockchain-api-client";
import { AppConfig, UserSession } from "@stacks/connect";
import { Storage } from "@stacks/storage";

export const STAICK_CLIENT_CONFIG = new AppConfig([
  "store_write",
  "publish_data",
]);
export const STACKS_USER_SESSION = new UserSession({
  appConfig: STAICK_CLIENT_CONFIG,
});

const hiroHeaders: HeadersInit = {
  "x-hiro-product": "bitcoin-badger-web",
  "x-hiro-version": "0.01",
};

export function fetchApi(input: RequestInfo, init: RequestInit = {}) {
  const initHeaders = init.headers || {};
  return fetch(input, {
    credentials: "omit",
    ...init,
    headers: { ...initHeaders, ...hiroHeaders },
  });
}

export async function fetchWithTimeout(
  input: RequestInfo,
  init: RequestInit & { timeout?: number } = {}
) {
  const { timeout = 8000, ...options } = init;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetchApi(input, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
}

function createConfig(basePath: string, anchored?: boolean) {
  const middleware: Middleware[] = [];

  return new Configuration({
    basePath,
    fetchApi,
    middleware,
  });
}

export const DEFAULT_TESTNET_SERVER =
  "https://stacks-node-api.testnet.stacks.co";
export const DEFAULT_MAINNET_SERVER = "https://stacks-node-api.stacks.co";

export const CONFIG_BC_S = createConfig(DEFAULT_MAINNET_SERVER);

export const MUTANT_MONKEY_CONTRACT =
  "SP125J1ADVYWGWB9NQRCVGKYAG73R17ZNMV17XEJ7.mutant-monkeys::mutant-monkeys";

export const CONTRACT_ADDRESS = "SPNWZ5V2TPWGQGVDR6T7B6RQ4XMGZ4PXTEE0VQ0S";
export const CONTRACT_NAME = "mutant-upgrade-v1";
export const CONTRACT_ADDRESS_NAME = `${CONTRACT_ADDRESS}.${CONTRACT_NAME}`;

export const GET_MUTANT_LEVEL_FUNC_NAME = "get-mutant-level";
export const UPGRADE_MUTANT_LEVEL_FUNC_NAME = "upgrade";
export const GET_TOTAL_MONKEY_AT_LEVEL = "get-count-of-mutants-at-level";

export const accountsApi = new AccountsApi(CONFIG_BC_S);
export const transactionsAPI = new TransactionsApi(CONFIG_BC_S);
export const smartContractAPI = new SmartContractsApi(CONFIG_BC_S);
export const blocksAPI = new BlocksApi(CONFIG_BC_S);
export const storageAPI = new Storage({ userSession: STACKS_USER_SESSION });
