import StateLogic from "../state";
import Home from "./Home";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import ConnectWallet from "./ConnectWallet";

function App() {
  return (
    <StateLogic>
      <ToastContainer />
      <div className="min-h-screen min-w-screen flex flex-col items-center bg-black">
        <ConnectWallet />
      </div>
    </StateLogic>
  );
}

export default App;
