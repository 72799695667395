import { AddressNftListResponse } from "@stacks/blockchain-api-client";
import { Connect } from "@stacks/connect-react";
import { useEffect, useState } from "react";
import { MONKEY_STATUS, MONKEY_TYPE, useAppState } from "../state";
import {
  accountsApi,
  MUTANT_MONKEY_CONTRACT,
  STACKS_USER_SESSION,
} from "../utils/auth";
import Home from "./Home";
import BN from "bn.js";

import monkeyMeta from "../assets/MonkeyMeta.json";

type BasicNFTHoldingResType = {
  limit: number;
  offset: number;
  total: number;
  results: any[];
};
const ConnectWallet = () => {
  const {
    authenticated,
    _authenticated,
    _senderAddress,

    _monkeys,
  } = useAppState();

  const [loading, _Loading] = useState<boolean>(false);

  useEffect(() => {
    const senderAddy = localStorage.getItem("walletID");

    if (senderAddy) {
      fetchMonkeys(senderAddy);
      _authenticated(true);
      _senderAddress(senderAddy);
    }
  }, []);

  const fetchMonkeys = async (senderAddress: string) => {
    try {
      _Loading(true);

      const STACKS_API = "https://stacks-node-api.mainnet.stacks.co/";

      //Fetch all NFT related to this wallet and monkey contract
      const _nftHoldingsAPIRes = await fetch(
        `${STACKS_API}extended/v1/tokens/nft/holdings?principal=${senderAddress}&asset_identifiers=${MUTANT_MONKEY_CONTRACT}`
      )
        .then((res) => res.text())
        .then((text) => text);

      if (_nftHoldingsAPIRes) {
        //Check if _nftHoldings is not empty, create func to fetch more NFT if wallet has more than 50
        const nftHoldings = JSON.parse(
          _nftHoldingsAPIRes
        ) as BasicNFTHoldingResType;

        const totalNFT = nftHoldings.total;

        let monkeysInWallet: any[] = nftHoldings.results;
        let totalFetchedNFT = nftHoldings.results.length;

        if (nftHoldings.total >= 50) {
          while (totalFetchedNFT < totalNFT) {
            const loopFetchData = await fetch(
              `${STACKS_API}extended/v1/tokens/nft/holdings?principal=${senderAddress}&asset_identifiers=${MUTANT_MONKEY_CONTRACT}&offset=${totalFetchedNFT}`
            )
              .then((res) => res.text())
              .then((text) => text);

            const _lpd = JSON.parse(loopFetchData);

            if (_lpd.results) {
              monkeysInWallet = [...monkeysInWallet, ..._lpd.results];
              totalFetchedNFT = totalFetchedNFT + _lpd.results.lenggh;
            }
          }
        }

        const _cleanedMonkeys: MONKEY_TYPE[] = [];

        //loop to check if metadata is presnet in existing json (if not fetch from byz)
        // after getting meta add it to _cleanedMonkeys
        for (const monkey of monkeysInWallet) {
          //Format ID
          const workAround = monkey as any;
          const _monkeyId = workAround.value.repr.replace(/\D/g, "");
          const monkeyId: number = +_monkeyId;

          const _monkeyMeta = monkeyMeta as any[];

          const monkeyLookUp = _monkeyMeta.find((d: any, i) => {
            if (d.status && d.status === "failed") {
              return false;
            } else {
              const _name = d.name.replace(/\D/g, "");
              const name = +_name;

              return monkeyId === name;
            }
          });

          if (monkeyLookUp) {
            const types: MONKEY_TYPE = {
              id: monkeyId,

              image: monkeyLookUp ? monkeyLookUp.image : "",

              status: MONKEY_STATUS.NOTHING,
              meta: monkeyLookUp,
            };

            _cleanedMonkeys.push(types);
          } else {
            const rest = await fetch(
              `https://w6d0eyjkea1lvhl-pq.adb.uk-london-1.oraclecloudapps.com/ords/cs/metadata/mutant_monkeys/${monkeyId}.json`
            )
              .then((res) => res.text())
              .then((text) => text);

            if (rest) {
              const cleaned = JSON.parse(rest);

              if (cleaned.status && cleaned.status === "failed") {
                return false;
              } else {
                const types: MONKEY_TYPE = {
                  id: monkeyId,

                  image: cleaned ? cleaned.image : "",

                  status: MONKEY_STATUS.NOTHING,
                  meta: cleaned,
                };

                _cleanedMonkeys.push(types);
              }
            }
          }
        }

        _monkeys(_cleanedMonkeys);
      }
    } catch (err) {
      console.log("noooo", err);
      _Loading(false);
    }
  };

  const AUTH_OPTIONS: any = {
    appDetails: {
      name: "Mutant Monkeys",
      icon: "https://cdn.discordapp.com/attachments/973373774101618778/978172941802029096/SLIME.png",
    },
    username: "test",
    redirectTo: "/",
    onFinish: async () => {
      try {
        _Loading(true);
        let userData = STACKS_USER_SESSION.loadUserData();

        const senderAddress = userData.profile.stxAddress.mainnet;

        _authenticated(true);
        _senderAddress(senderAddress);

        _Loading(false);

        fetchMonkeys(senderAddress);
        localStorage.setItem("walletID", senderAddress);
      } catch (err) {
        console.log("noooo", err);
        _Loading(false);
      }
    },
    userSession: STACKS_USER_SESSION,
  };

  return (
    <Connect authOptions={AUTH_OPTIONS}>
      <Home />
    </Connect>
  );
};

export default ConnectWallet;
